<template>
  <div
    v-if="release"
  >
    <b-link
      v-b-modal="`modal-edit-release-${release.id}`"
      class="font-weight-bold d-block link-hover"
    >
      {{ release.version }}
    </b-link>
    <b-modal
      :id="`modal-edit-release-${release.id}`"
      ok-only
      size="xl"
      centered
      title="Détail de la version"
    >
      <b-row>
        <b-col cols="12">
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="decodedDockerComposeContent" />
          <!--eslint-enable-->
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal,
  BCol, BRow, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BCol,
    BRow,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    release: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      decodedDockerComposeContent: null,
      encodedDockerComposeContent: this.release.dockerComposeContents,
    }
  },
  mounted() {
    this.decodedDockerComposeContent = atob(this.encodedDockerComposeContent).replaceAll('\n', '<br>').replaceAll(' ', '&nbsp;&nbsp;&nbsp;')
  },
}
</script>
