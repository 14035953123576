<template>
  <div
    v-if="$store.state.auth.role === 1"
  >
    <b-card no-body>
      <!-- Table Container Card -->
      <div
        class="mb-0"
      >
        <!-- Table Top -->
        <b-row
          class="m-2"
        >

          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-center"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Utilisateurs</label>
          </b-col> -->

          <!-- Search -->
          <!-- <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-form class="w-100">
                <b-input-group>
                  <b-form-input
                    placeholder="Recherche par mots-clés"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      type="submit"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </div>
          </b-col> -->
        </b-row>

        <b-table
          v-if="releasesList.length > 0"
          class="position-relative"
          :items="releasesList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Aucune version trouvé"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(version)="data">
            <modal-edit-release :release="data.item" />
          </template>

          <template #cell(channel)="data">
            <p>
              {{ data.item.channel }}
            </p>
          </template>

          <template #cell(readme)="data">
            <small>
              {{ data.item.readme }}
            </small>
          </template>

          <template #cell(createdAt)="data">
            <small>
              {{ data.item.createdAt | displayDate }}
            </small>
          </template>
        </b-table>
        <div
          v-else
          class="text-center"
        >
          <b-spinner
            v-if="isLoading"
            class="mt-5 mb-5"
          />
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ dataMeta.from }} à {{ dataMeta.to }} sur {{ dataMeta.of }} releases</span>
            </b-col>
            <!-- Pagination -->
            <!-- <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col> -->
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
  BSpinner, VBTooltip,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ModalEditRelease from './ModalEditRelease.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BSpinner,
    ModalEditRelease,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      searchField: null,
      releasesList: [],
      totalReleases: null,
      tableColumns: [
        { key: 'version', label: 'Version', sortable: true },
        { key: 'channel', label: 'Channel', sortable: true },
        { key: 'readme', label: 'Readme', sortable: true },
        { key: 'createdAt', label: 'Créé le', sortable: true },
      ],
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: 'id',
      searchQuery: null,
      isSortDirDesc: true,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.releasesList.length ? this.releasesList.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalReleases,
      }
    },
  },
  watch: {
    perPage(newData) {
      this.perPage = newData
      this.currentPage = 1
      this.getReleases()
    },
    currentPage(newData) {
      this.currentPage = newData
      this.getReleases()
    },
    isSortDirDesc(newData) {
      this.currentPage = 1
      this.isSortDirDesc = newData
      this.getReleases()
    },
    sortBy(newData) {
      this.currentPage = 1
      this.sortBy = newData
      this.getReleases()
    },
    searchQuery(newData) {
      this.currentPage = 1
      this.searchQuery = newData
      this.getReleases()
    },
  },
  created() {
    this.getReleases()
  },
  methods: {
    resolveActiveVariant(data) {
      if (data) return 'success'
      if (!data) return 'danger'
      return 'dark'
    },
    getReleases() {
      this.isLoading = true
      // const buildLimit = `?limit=${(this.currentPage - 1) * this.perPage},${this.perPage}&sort=${this.sortBy},${this.isSortDirDesc ? '-1' : '1'}`
      store.dispatch('release/findAllReleases')
        .then(response => {
          this.releasesList = response
          this.totalReleases = response.length
          this.isLoading = false
        })
        .catch(err => {
          console.log('err', err)
          this.isLoading = false
          this.releasesList = []
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
